<template>
  <div class="toolLogDetails">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>日志详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form label-width="80px">
      <el-form-item label="营销工具名称">
        <span>{{ formData.marketingName }}</span>
      </el-form-item>
      <el-form-item label="修改人 ">
        <span>{{ formData.masterName }}</span>
      </el-form-item>
      <el-form-item label="修改时间">
        <span>{{ formData.addTime }}</span>
      </el-form-item>
      <el-form-item label="	修改内容类型">
        <span>{{ formData.updateType }}</span>
      </el-form-item>
      <el-form-item label="	修改前内容">
        <span>{{ formData.curContent }}</span>
      </el-form-item>
      <el-form-item label="修改后内容">
        <span>{{ formData.endContent }}</span>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="$router.go(-1)">返回</el-button>
  </div>
</template>
<script>
import { toolLogDetails } from "@/api/tool";
export default {
  name: "toolLogDetails",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  created() {
    this.getticketLogDetails();
  },
  methods: {
    async getticketLogDetails() {
      const { data } = await toolLogDetails({
        logId: Number(this.id),
      });
      console.log(data);
      this.formData = data.data;
    },
  },
};
</script>
<style lang="less" scoped>
.toolLogDetails {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
}
</style>